<template>
  <b-card
    no-body
  >
    hello
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  setup() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
